.nav-item{
    padding: 0 1.75rem !important;
}
.nav_border{
    border-bottom: 1px solid #F78154 !important;
}
#navigation .nav-link{
    color: black !important;
    font-size: 1.05rem;
    letter-spacing: 0.1rem;
    transition: 0.5s ease-in-out;
    
}
#navigation .nav-link:hover{
    color: #F78154 !important;
    font-size: 1.05rem;
}
.address-writing a{
    font-size: 0.75rem;
    color: black !important;
}
.navbar-brand{
    transition: 0.4s ease-in-out;
}
.navbar-brand:hover{
    transform:scale(1.10);
}
.nav-menu-btn{
    z-index: 2 !important;
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 80px;
    cursor: pointer;
    margin: 0.8rem;
    transition: all .5s ease-in-out;
    background-color: white;
    border-radius: 25px;

}
.nav-menu-btn__burger{
    z-index: 2 !important;
    width: 20px;
    height: 2px;
    background: #F78154;
    transition: all .5s ease-in-out;
    position: fixed;
}
.nav-menu-btn__burger::before,
.nav-menu-btn__burger::after{
    z-index: 2 !important;
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    margin-top: -5px;
    background: #F78154;
    transition: all .5s ease-in-out;
}
.nav-menu-btn__burger::before{
    transform: translateY(-16px);
    margin-top: 5px;
}
.nav-menu-btn__burger::after{
    transform: translateY(16px);
}
/*Animation*/
.nav-menu-btn.open .nav-menu-btn__burger{
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}
.nav-menu-btn.open .nav-menu-btn__burger::before{
    transform: rotate(39.5deg) translate(35px, -35px);
}
.nav-menu-btn.open .nav-menu-btn__burger::after{
    transform: rotate(-39.5deg) translate(35px, 35px);
}
.mobile-logo{
    position: absolute;
}
.mobile-nav{
    z-index: 2 !important;
    width: 100%;
    position:fixed;
}
.mobile-nav__outer.border-bottom{
    height: 17vh;
    border-color: #F78154 !important;
}
.mobile-nav__position{
    position:fixed;
    width: 100% !important;
}
.mobile-nav__background{
    background-color: white;
}
.mobile-nav__background.border-bottom{
    border-color: #F78154 !important;
}
.nav-item.highlight:hover{
    transform:scale(1.15);
}
.nav-logo:hover{
    transform:scale(1.2);
}

.mobile-nav-link__texts{
    color: black !important;
    letter-spacing: 0.2rem;
}

@media only screen and (min-width: 805px){
    .mobile-nav{
        display:none;
    }
    .mobile-nav__outer.border-bottom{
        display:none;
    }
}
@media only screen and (min-width: 426px) and (max-width: 768px){
    .nav-menu-btn{
        margin-right: 50px !important;
    }
}
@media only screen and (max-width: 806px){
    .nav-lgscreen{
        display:none;
    }
    .nav-item.highlight:hover{
        border-bottom: solid 0.5px #F78154 !important;
    }
}
/* 
@keyframes slideInFromLeft {
    0%{
        transform: translateX(100%);
    }
    25%{
        trnasform: translateX(75%);
    }
    50%{
        transform: translateX(50%);
    }
    75%{
        transform: translateX(25%);
    }
    100%{
        transform: translateX(0%);
    }
} */