@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap');
* {
    /* font-family: 'Source Serif Pro', serif !important; */
    /* font-family: 'Cinzel', serif !important; */
    font-family: 'Lato', sans-serif;
}
/* :root{
    background-color:#F2C14E !important;
} */



