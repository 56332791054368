@import './index.css';

.homepage {
    padding-top: 7rem;
}
.our-best-seller-font{
    margin-top: 8rem !important;
}
.horizontal-line {
    border-bottom: 0.9px solid #F78154;
    height: 20px;
}

.review-section__top-margin {
    margin-top: 15rem;
}

.our-best-seller-font {
    letter-spacing: 0.15rem;
    text-shadow: 2px 1px rgba(128, 128, 128, 0.656) !important;
    -webkit-text-fill-color: #f78254b6 !important;
    display: table;
    margin: 0 auto;
    background-color: white;
}

.reviews-margin__top {
    margin-top: 8em !important;
}

.border-thickness {
    border-width: 1px !important;
}

.btn-color {
    border: 0.9px solid !important;
    border-color: #F78154 !important;
    background-color: #f7825430;
}